/* eslint-disable react/display-name */
import { useEffect, useState } from "react";
import { useCells, useParties, useProducts } from "@/store";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Input, message, Row, Space, Switch } from "antd";
import { useDebounce } from "@/hooks/useBounce";
import _ from "lodash";

import "./styles.scss";
import { useMutation } from "react-query";
import services from "@/services";
import { AxiosError } from "axios";
import { useAuth } from "@/hooks/useAuth";

export const MainActions = ({ searchRef }: any) => {
  const setVisible = useCells(({ setVisibleModal }) => setVisibleModal);
  const setIsSeparately = useCells(({ setIsSeparately }) => setIsSeparately);
  const isSeparately = useCells(({ isSeparately }) => isSeparately);
  const setVisibleGroupModal = useCells(
    ({ setVisibleGroupModal }) => setVisibleGroupModal
  );
  const allProducts = useProducts(({ allProducts }) => allProducts);
  const setProducts = useProducts(({ setProducts }) => setProducts);
  const selectedRowKeys = useProducts(({ selectedRowKeys }) => selectedRowKeys);
  const { getParty } = useAuth();

  const party = useParties(({ party }) => party);
  const setParty = useParties(({ setParty }) => setParty);

  const [search, setSearch] = useState("");

  const debounceSearch = useDebounce(search, 500);

  useEffect(() => {
    if (debounceSearch?.length > 0) {
      const _data = allProducts.filter(
        (item) => item.track_number.indexOf(debounceSearch) > -1
      );
      setProducts(_data);
    } else {
      setProducts(allProducts);
    }
  }, [debounceSearch, allProducts]);

  const onChange = ({ target }: any) => {
    setSearch(target.value);
  };

  const isAllIdsEqual = (array: any) =>
    _.every(array, (item) => _.isEqual(item?.user?.id, array[0]?.user?.id));

  const { mutate, isLoading } = useMutation(
    ({ id, data }: any) => services.products.changePartyIsAvto({ id, data }),
    {
      onSuccess: (data) => {
        // setParty(data);
        if (data?.data) {
          setParty(data?.data);
        }
        message.success("changed");
      },
      onError: (err: AxiosError) => {
        if (err?.response && err?.response?.data?.message) {
          message.error(err?.response?.data?.message);
          return;
        }
        message.error("Something went error");
      },
      onSettled: () => {
        // queryClient.invalidateQueries("getCell");
        getParty();
      },
    }
  );

  const handleToggleIsAvto = (checked: boolean) => {
    mutate({ id: party?.id, data: { isAvto: checked } });
  };

  return (
    <div className="container main-actions">
      <Row align="middle" gutter={8}>
        <Col span={12}>
          <Input
            size="large"
            placeholder="Search by Track Number"
            suffix={<SearchOutlined />}
            className="main-search"
            ref={searchRef}
            onChange={onChange}
            value={search}
            allowClear
          />
        </Col>
        <Col span={12}>
          <Row justify="end">
            <Space size="middle">
              {/* <span>
                <label
                  style={{
                    marginRight: "10px",
                    fontWeight: `${party?.isAvto ? "bold" : "normal"}`,
                    color: `${party?.isAvto ? "red" : "black"}`,
                    fontSize: `${party?.isAvto ? "1.4rem" : "14px"}`,
                  }}
                >
                  IS AUTO
                </label>
                <Switch
                  checked={party?.isAvto}
                  onChange={(checked) => handleToggleIsAvto(checked)}
                />
              </span> */}
              <span>
                <label
                  style={{
                    marginRight: "10px",
                    fontWeight: `${isSeparately ? "bold" : "normal"}`,
                    color: `${isSeparately ? "red" : "black"}`,
                    fontSize: `${isSeparately ? "1.4rem" : "14px"}`,
                  }}
                >
                  Add Separately
                </label>
                <Switch
                  checked={isSeparately}
                  onChange={(checked) => setIsSeparately(checked)}
                />
              </span>
              <Button
                onClick={() => setVisible(true)}
                size="large"
                type="dashed"
                className="main-action-btn"
              >
                Open Cell
              </Button>

              <Button
                size="large"
                type="primary"
                disabled={
                  !isAllIdsEqual(selectedRowKeys) ||
                  selectedRowKeys?.length === 0
                }
                className="main-action-btn"
                onClick={() => setVisibleGroupModal(true)}
              >
                Divide Product{selectedRowKeys?.length > 1 && "s"}
              </Button>
            </Space>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
